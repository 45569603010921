export function addNoDataLayer(mapInstance) {
  const layer = "noData-layer";
  const noDataSource = {
    type: "vector",
    url: "mapbox://aashary.4l7f9d2j",
  };

  // Add the new tileset layer
  const noDataLayer = {
    id: layer,
    type: "fill",
    source: noDataSource,
    "source-layer": "gadm_v410_activity_filtered-9rpnqj",
    paint: {
      "fill-color": "#5a5a5a", // dark grey
      "fill-opacity": 0.3,
    },
  };

  mapInstance.addSource("noData-src", noDataSource);
  mapInstance.addLayer(noDataLayer);

  mapInstance.on("click", layer, async function (e) {
    // this will prevent user from clicking through the layer to admin 1 or 2
    e.originalEvent.cancelMapInteractivityOnEventClick = true;
  });
}

// adds an outline to access data on selected item
export async function addOutlineLayer(map, source, layer, color) {
  if (await map.getLayer(layer)) {
    await map.removeLayer(layer);
  }
  // Check if the fill layer exists and remove it if it does
  if (await map.getLayer(`${layer}-fill`)) {
    await map.removeLayer(`${layer}-fill`);
  }

  // Add the fill layer
  await map.addLayer({
    id: `${layer}-fill`,
    source: source,
    type: "fill",
    paint: {
      "fill-color": color,
      "fill-opacity": 0.1,
    },
  });
  await map.addLayer({
    id: layer,
    source: source,
    type: "line",
    paint: {
      "line-color": color,
      "line-width": 3,
      "line-opacity": 1,
      "line-opacity-transition": { duration: 1000 },
    },
  });
}

// adds a transparent layer to access data on click
export async function addTransparentLayer(map, source, layer) {
  if (map.getLayer(layer)) {
    await map.removeLayer(layer);

    if (map.getLayer(layer + "-border")) {
      await map.removeLayer(layer + "-border");
    }
  }
  await map.addLayer({
    id: layer,
    type: "fill",
    source,
    paint: {
      "fill-color": "transparent",
      "fill-opacity": 1,
    },
  });
  await map.addLayer({
    id: layer + "-border",
    source,
    type: "line",
    paint: {
      "line-color": "white",
      "line-width": 1,
      "line-opacity": 0.5,
    },
    interactive: false, // Make the layer non-clickable
  });
}


import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { admin, maps, layers, constants } from "./helpers";
import Scale from "./components/Scale";
import { useCurrentSelection, useCurrentMap } from "../../contexts";
import Coordinates from "./components/Coordinates";


// this comment and mapboxgl.workerClass NEED to be right under each other
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Mapbox = () => {
  const { currentSelection } = useCurrentSelection();
  const { currentMap } = useCurrentMap();

  // [constants.admin0 (country), constants.admin1 (state), constants.admin2 (county)]
  const [selectedGIDs, setSelectedGIDs] = useState(["BRA", null, null]);
  const [loadedGIDs, setLoadedGIDs] = useState([null, null, null]);
  const [loadedChildren, setLoadedChildren] = useState([null, null, null]);
  const [lngLat, setLngLat] = useState({ lng: 0, lat: 0 });

  const adminLevelRef = useRef(currentSelection.adminLevel);
  const sameAdmin0Ref = useRef();
  const sameAdmin1Ref = useRef();

  const getUpdatedStateArray = (
    updatedArray,
    selectedAdmin,
    selectedGID,
    newAdmin0
  ) => {
    if (selectedAdmin === constants.admin0) {
      updatedArray[constants.admin0] = selectedGID;
      updatedArray[constants.admin1] = null;
      updatedArray[constants.admin2] = null;
    } else if (selectedAdmin === constants.admin1) {
      if (updatedArray[constants.admin0] !== newAdmin0) {
        updatedArray[constants.admin0] = newAdmin0;
      }
      updatedArray[constants.admin1] = selectedGID;
      updatedArray[constants.admin2] = null;
    } else if (selectedAdmin === constants.admin2) {
      if (updatedArray[constants.admin0] !== newAdmin0) {
        updatedArray[constants.admin0] = newAdmin0;
      }
      if (updatedArray[constants.admin1] !== admin.getAdmin1Code(selectedGID)) {
        updatedArray[constants.admin1] = admin.getAdmin1Code(selectedGID);
      }
      updatedArray[constants.admin2] = selectedGID;
    }
    return updatedArray;
  };

  const updateLoadedState = (selectedAdmin, selectedGID) => {
    const updateArray = [...loadedGIDs];
    const newAdmin0 = admin.getAdmin0Code(selectedGID);

    setLoadedGIDs(
      getUpdatedStateArray(updateArray, selectedAdmin, selectedGID, newAdmin0)
    );
  };

  const updateChildrenState = (selectedAdmin, selectedGID) => {
    const updateArray = [...loadedChildren];
    const newAdmin0 = admin.getAdmin0Code(selectedGID);
    setLoadedChildren(
      getUpdatedStateArray(updateArray, selectedAdmin, selectedGID, newAdmin0)
    );
  };

  const updateSelectedGidState = (selectedAdmin, selectedGID) => {
    const updateArray = [...selectedGIDs];
    const newAdmin0 = admin.getAdmin0Code(selectedGID);

    setSelectedGIDs(
      getUpdatedStateArray(updateArray, selectedAdmin, selectedGID, newAdmin0)
    );

    syncMapWithState(updateArray);
  };

  function countNulls(arr) {
    return arr.filter(item => item === null).length;
  }
  const downLayer = (updateArray) => {
    return countNulls(updateArray) > countNulls(loadedGIDs);
  }

  const syncMapWithState = async (updateArray) => {
    // if we are going from admin 1 to 0 we need to remove all layers and add back
    const down = downLayer(updateArray);

    // Check for inconsistencies and remove old layers if needed
    if (updateArray[constants.admin0] !== loadedGIDs[constants.admin0] || down) {
      await layers.removeSourceLayer(currentMap.mapRef.current, constants.selectedOutline, constants.admin0src);
      await layers.removeSourceLayer(currentMap.mapRef.current, constants.selectedOutlineFill, constants.admin0src);
      await layers.removeSourceLayer(currentMap.mapRef.current, constants.admin0layer, constants.admin0src);
      await layers.removeSourceLayer(
        currentMap.mapRef.current,
        `${constants.admin0layer}-children`,
        `${constants.admin0src}-children`
      );
    }
    if (updateArray[constants.admin1] !== loadedGIDs[constants.admin1] || down) {
      await layers.removeSourceLayer(currentMap.mapRef.current, constants.selectedOutline, constants.admin1src);
      await layers.removeSourceLayer(currentMap.mapRef.current, constants.selectedOutlineFill, constants.admin1src);
      await layers.removeSourceLayer(currentMap.mapRef.current, constants.admin1layer, constants.admin1src);
      await layers.removeSourceLayer(
        currentMap.mapRef.current,
        `${constants.admin1layer}-children`,
        `${constants.admin1src}-children`
      );
    }
    if (updateArray[constants.admin2] !== loadedGIDs[constants.admin2] || down) {
      await layers.removeSourceLayer(currentMap.mapRef.current, constants.selectedOutline, constants.admin2src);
      await layers.removeSourceLayer(currentMap.mapRef.current, constants.selectedOutlineFill, constants.admin2src);
      await layers.removeSourceLayer(currentMap.mapRef.current, constants.admin2layer, constants.admin2src);
    }

    // Load layers based on updated state
    if (updateArray[constants.admin0] && (loadedGIDs[constants.admin0] !== updateArray[constants.admin0] || down)) {
      await maps.loadAdmin0Data(
        currentMap.mapRef.current,
        updateArray[constants.admin0],
        constants.admin0,
        updateLoadedState,
        updateChildrenState,
        handleAdmin0ChildrenClick,
        currentSelection.setDisplayText
      );
    }
    if (updateArray[constants.admin1] && (loadedGIDs[constants.admin1] !== updateArray[constants.admin1] || down)) {
      await maps.loadAdmin1Data(
        currentMap.mapRef.current,
        updateArray[constants.admin1],
        constants.admin1,
        updateLoadedState,
        updateChildrenState,
        handleAdmin1ChildrenClick,
        currentSelection.setDisplayText
      );
    }
    if (updateArray[constants.admin2] && (loadedGIDs[constants.admin2] !== updateArray[constants.admin2] || down)) {
      await maps.loadAdmin2Data(
        currentMap.mapRef.current,
        updateArray[constants.admin2],
        constants.admin2,
        updateLoadedState,
        handleAdmin2ChildrenClick,
        currentSelection.setDisplayText
      );
    }
  };

  const handleAdmin0ChildrenClick = (e) => {
    maps.handleChildrenClick(e, 1, currentSelection.gidRef, currentSelection.setSelection, currentMap.mapRef.current); // Admin 0 children are admin 1
  };

  const handleAdmin1ChildrenClick = (e) => {
    maps.handleChildrenClick(e, 2, currentSelection.gidRef, currentSelection.setSelection, currentMap.mapRef.current); // Admin 1 children are admin 2
  };

  // Assuming admin level 2 doesn't have children, but you can define handleAdmin2ChildrenClick if needed
  const handleAdmin2ChildrenClick = (e) => {
    return;
  };

  /* INITIATION FUNTIONS */

  useEffect(() => {  
    if (currentMap.mapLoaded && currentSelection.gidRef && currentSelection.gid) {
      sameAdmin0Ref.current = admin.isSameAdmin0(currentSelection.gidRef.current, currentSelection.gid);
      sameAdmin1Ref.current = admin.isSameAdmin1(currentSelection.gidRef.current, currentSelection.gid);
      adminLevelRef.current = currentSelection.adminLevel;

      updateSelectedGidState(currentSelection.adminLevel, currentSelection.gid);
    }
  
    return () => {
      if (currentMap.mapRef?.off) {
        currentMap.mapRef.off("click", handleAdmin0ChildrenClick);
        currentMap.mapRef.off("click", handleAdmin1ChildrenClick);
        currentMap.mapRef.off("click", handleAdmin2ChildrenClick);
      }
    };
  }, [currentMap.mapLoaded, currentSelection.gid, currentSelection.adminLevel]);

  useEffect(() => {
    currentMap.mapRef.current?.on('mousemove', (e) => {
      setLngLat(e.lngLat);
    });
  }, [currentMap.mapRef.current]);
  
  return (
    <div className="relative">
      <div id="map" ref={currentMap.map} className="h-screen w-full -mt-20" />
      {currentMap.mapRef?.current && (
        <>
          <Scale />
          <Coordinates />
        </>
      )}
    </div>
  );
};

export default Mapbox;

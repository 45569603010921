import clsx from "clsx";
import Circle from "./components/Circle";
import { addColorsToChartRange } from "../Charts/helpers";
import { roadsPlanet } from "../../../../../constants";
import * as context from "../../../../../contexts";

export default function Legend() {
  const { currentChart } = context.useCurrentChart();
  const { currentSelection } = context.useCurrentSelection();
  const isLayerRoads = currentSelection.selectedLayer.product === roadsPlanet;
  const chartRange = currentChart.chartRange;
  const legendItems = addColorsToChartRange([
    Math.min(...chartRange),
    Math.max(...chartRange),
  ]);

  if (isLayerRoads) {
    const roadsLegendItems = {
      hex: "#d3d3d3",
      tailwind: "offWhite",
      label: "2016",
    };
    legendItems.unshift(roadsLegendItems);
  }

  return (
    <div className={parentWrapperStyle}>
      {Object.values(legendItems).map((item) => (
        <div key={item.hex} className="flex items-center gap-2">
          <Circle backgroundColor={item.hex} />
          <div className={labelStyle}>{item.label}</div>
        </div>
      ))}
    </div>
  );
}

const parentWrapperStyle = clsx(
  "w-full max-w-[410px] pt-6 gap-3 flex flex-wrap mb-2 -mt-3 m-auto justify-center"
);
const labelStyle = clsx(
  "opacity-90 text-black text-sm leading-tight font-mono"
);

import clsx from "clsx";
import { activityTypes } from "../../../../../constants";
import { formatNumber } from "../Charts/helpers";
import { noDataText } from "../Charts/constants";

export default function AggregateSummary({ data }) {
  return data ? (
    <div className={aggSummaryContentContainerStyle}>
      <div className="w-full overflow-x-hidden">
        <table className="min-w-full bg-white text-sm">
          <tbody className="bg-white">
            {data?.map((row) => (
              <tr className="" key={row.activity}>
                <td className={aggSummaryTextStyle}>
                  {activityTypes[row.activity_type]}
                </td>
                <td className={aggSummaryValueUnitStyle}>
                  <div className="text-black text-sm font-bold">{formatNumber(row.area, row.unit)}</div>
                  <div className="text-neutral-500 font-light text-xs">
                    {row.unit}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <>{noDataText}</>
  );
}

const aggSummaryContentContainerStyle = clsx(
  "w-full flex items-start justify-between bg-white py-2 my-2"
);
const aggSummaryTextStyle = clsx(
  "py-2 whitespace-nowrap text-sm text-gray-900"
);
const aggSummaryValueUnitStyle = clsx(
  aggSummaryTextStyle + " flex justify-end items-center gap-1 font-mono"
);

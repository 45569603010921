import React from "react";

export const lineStyles = [
  { color: "black", type: "solid" }, // Solid line
  { color: "black", dasharray: "5,5", type: "dashed" }, // Dashed line
  { color: "black", dasharray: "2,2", type: "dotted" }, // Dotted line
];

export const margin = { top: 12, right: 0, bottom: 22, left: 48 };
export const width = 328 - margin.left - margin.right;
export const height = 254 - margin.top - margin.bottom;

export const noDataText = (
  <div className="p-4 text-center">
    Data available as of September 2024 are for humid tropical regions. For global data,
    please check out our{" "}
    <a
      className="underline hover:text-black/50"
      href="https://ctrees.org/luca"
      target="_blank"
      rel="noopener noreferrer"
    >
      Land Use Change Alert
    </a>
    {" "} and {" "}
    <a
      className="underline hover:text-black/50"
      href="https://ctrees.org/jmrv"
      target="_blank"
      rel="noopener noreferrer"
    >
      Jurisdictional MRV
    </a>
    {" "}tools.
  </div>
);

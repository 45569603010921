import mapboxgl from "mapbox-gl";
import { getAdmin, getAdminChildrenUrl } from "../endpoints";
import { getAdminCodeFromGID } from "../admin";
import {
  moveToCoordinates,
  moveToGeoJson,
  moveViewToClickedLayer,
} from "../view";
import { addTransparentLayer, addOutlineLayer, setLayerOrder } from "../layers";
import { addGeoJsonSource } from "../geojson";
import {
  selectedOutline,
  admin0src,
  admin1src,
  admin0layer,
  admin1layer,
  admin2,
} from "../constants";
import { layerOptions, minZoom, maxZoom } from "../../../../constants";
import { clickable } from "../layers/constants";

const cancelEvent = (e) => {
  return e.originalEvent.cancelMapInteractivityOnEventClick;
};

const isClickableLayer = (e, map) => {
  // Get all features/layers at the click point if they are 'constants.clickable' layer (special event)
  const clickableFeatures = map.queryRenderedFeatures(e.point, {
    layers: [clickable],
  });

  // If the click was on 'constants.clickable', do nothing and return true
  if (clickableFeatures.length) {
    return true; 
  }

  return false;
}

export const updateRasterLayer = (map, layer, url) => {
  const sourceId = `${layer}-source`;
  const layerId = `${layer}-layer`;

  // when new layer is selected, remove the toggle layer (stable forest)
  if (layer === "selected" && map.getLayer("toggle-layer")) {
    map.removeLayer("toggle-layer");
    map.removeSource("toggle-source");
  }

  if (map.getSource(sourceId)) {
    // If source exists, update its tiles property
    map.getSource(sourceId).setTiles([url]);
  } else {
    // If source does not exist, add it and the corresponding layer
    map.addSource(sourceId, {
      type: "raster",
      tiles: [url],
      tileSize: 256,
    });

    map.addLayer(
      {
        id: layerId,
        type: "raster",
        source: sourceId,
      },
      "country-boundaries-outline-layer"
    ); // Add the layer before "country-boundaries-outline-layer"
  }
};

const addAdmin0Layer = (
  map,
  selectedGID,
  setSelection
) => {
  const admin0BaseLayer = "gadm_v410_admin0_global";
  return new Promise((resolve, reject) => {
    try {
      const countryBoundariesSrc = {
        type: "vector",
        url: `mapbox://aashary.${admin0BaseLayer}`,
      };
      const countryBoundariesLayer = {
        id: "country-boundaries-layer",
        type: "fill",
        source: countryBoundariesSrc,
        "source-layer": admin0BaseLayer,
        paint: {
          "fill-color": "transparent",
          "fill-opacity": 1,
        },
        minZoom,
        maxZoom,
      };
      const countryBoundariesOutlineLayer = {
        id: "country-boundaries-outline-layer",
        type: "line",
        source: countryBoundariesSrc,
        "source-layer": admin0BaseLayer,
        paint: {
          "line-color": "white",
          "line-width": 1,
          "line-opacity": 0.3,
        },
        interactive: false, // Make the layer non-clickable
      };

      map.addSource("country-boundaries-src", countryBoundariesSrc);
      map.addLayer(countryBoundariesLayer);
      map.addLayer(countryBoundariesOutlineLayer);

      map.on("click", "country-boundaries-layer", async function (e) {        
        if(isClickableLayer(e, map)) return;

        try {
          const gid = e.features[0].properties.GID_0;

          setSelection(gid, 0);
        } catch (error) {
          console.log("something went wrong", error);
        }
      });

      resolve(); // Resolve the promise once the layers are added
    } catch (error) {
      reject(error); // Reject the promise if there's an error
    }
  });
};

export const onMapLoad = (
  map,
  selectedGID,
  setSelection
) => {
  // Add zoom in/zoom out control
  var nav = new mapboxgl.NavigationControl();
  map.addControl(nav, "bottom-right");

  // Add the admin0 layer
  addAdmin0Layer(map, selectedGID, setSelection)
    .then(() => {
      // Once the layer is added, update the raster layer
      updateRasterLayer(map, "selected", layerOptions[0].serverCall);
    })
    .catch((error) => {
      console.error(
        "Error adding admin0 layer or updating raster layer:",
        error
      );
    });

  // Handle map errors
  map.on("error", function (e) {
    console.log("Map error:", e.error.message);
  });
};

export const loadData = async (
  map,
  selectedGID,
  selectedAdmin,
  handleLayerClick,
  updateLoadedState,
  setDisplayText
) => {
  const gid = selectedGID;
  const source = `admin-${selectedAdmin}-src`;
  const layer = `admin-${selectedAdmin}-layer`;

  // Get data and move to coordinates
  const data = await getAdmin(gid);
  setDisplayText(data?.name);

  // check if there are any popups open so we don't move the map to admin center
  const popups = document.getElementsByClassName("mapboxgl-popup");
  if (popups.length < 1) {
    if (
      data?.center_longitude &&
      data?.center_latitude &&
      getAdminCodeFromGID(gid) === 0
    ) {
      moveToCoordinates(
        map,
        [data.center_longitude, data.center_latitude],
        gid
      );
    } else if (selectedAdmin === getAdminCodeFromGID(gid)) {
      moveToGeoJson(map, data?.url);
    }
  }

  // Add GeoJSON source and layers
  await addGeoJsonSource(map, data?.url, source);
  await addTransparentLayer(map, source, layer);
  await addOutlineLayer(map, source, selectedOutline, "#dff840");

  updateLoadedState(selectedAdmin, selectedGID);

  if (handleLayerClick) {
    map.on("click", layer, handleLayerClick);
  }

  setLayerOrder(map);
};

export const addChildrenLayer = async (
  map,
  adminLevelGID,
  source,
  layer,
  adminLevel,
  handleClick,
  updateChildrenState
) => {
  const url = await getAdminChildrenUrl(adminLevelGID);

  await addGeoJsonSource(map, url, source);
  await addTransparentLayer(map, source, layer);

  updateChildrenState(adminLevel, adminLevelGID);

  if (handleClick) {
    map.on("click", layer, handleClick);
  }
};

export const loadAdmin0Data = async (
  map,
  selectedGID,
  selectedAdmin,
  updateLoadedState,
  updateChildrenState,
  handleClickState,
  setDisplayText
) => {
  await loadData(
    map,
    selectedGID,
    selectedAdmin,
    handleClickState,
    updateLoadedState,
    setDisplayText
  );
  await addChildrenLayer(
    map,
    selectedGID,
    `${admin0src}-children`,
    `${admin0layer}-children`,
    0,
    handleClickState,
    updateChildrenState
  );
};

export const loadAdmin1Data = async (
  map,
  selectedGID,
  selectedAdmin,
  updateLoadedState,
  updateChildrenState,
  handleClickState,
  setDisplayText
) => {
  await loadData(
    map,
    selectedGID,
    selectedAdmin,
    handleClickState,
    updateLoadedState,
    setDisplayText
  );
  await addChildrenLayer(
    map,
    selectedGID,
    `${admin1src}-children`,
    `${admin1layer}-children`,
    1,
    handleClickState,
    updateChildrenState
  );
};
export const loadAdmin2Data = async (
  map,
  selectedGID,
  selectedAdmin,
  updateLoadedState,
  handleClickState,
  setDisplayText
) => {
  await loadData(
    map,
    selectedGID,
    selectedAdmin,
    handleClickState,
    updateLoadedState,
    setDisplayText
  );
  // Assuming admin level 2 is the deepest level and doesn't have children
};

export const handleChildrenClick = (
  e,
  adminLevel,
  gidRef,
  setSelection,
  map
) => {
  if (cancelEvent(e)) return;

  const gidProp = `GID_${adminLevel}`;
  const nameProp = `NAME_${adminLevel}`;
  const gid = e.features[0].properties[gidProp];
  const sameGid = gidRef === gid;

  if (!sameGid && gidRef && gid) {
    const name = e.features[0].properties[nameProp];
    const country = e.features[0].properties.COUNTRY;
    const clickedCoords = [e.lngLat.lng, e.lngLat.lat];
    if (adminLevel === admin2) {
      const name1 = e.features[0].properties["NAME_1"];
      setSelection(gid, adminLevel);
    } else {
      setSelection(gid, adminLevel);
    }
    moveViewToClickedLayer(
      map,
      gidRef,
      e.features[0].geometry.coordinates[0],
      clickedCoords
    );
  }
};

/* ENDPOINTS FOR MAP DATA */
const apiPrefix = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}`;

export async function getAdmin(gid) {
  const response = await fetch(`${apiPrefix}/gis/admin?gid=${gid}`);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getAdminChildrenUrl(gid) {
  const response = await fetch(`${apiPrefix}/gis/admin_children?gid=${gid}`);
  if (response.status === 200) {
    const data = await response.json();
    return data.url;
  } else {
    return;
  }
}

export async function getStats(gid, product) {
  const response = await fetch(
    `${apiPrefix}/activity/data_categories?product_filter=${product}&gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getBaseline(gid, product) {
  const response = await fetch(
    `${apiPrefix}/activity/data_baseline?product_filter=${product}&gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getTooltip(gid, product) {
  const group_name = product.split('-')[2]; // e.g. "ctrees-deforestation-planet-biannual" -> "planet"
  const response = await fetch(
    `${apiPrefix}/activity/tooltip_agg?group_name=${group_name}&gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getSpecialEvents() {
  const response = await fetch(
    `${apiPrefix}/activity/special_events`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getAdminNameTree() {
  const response = await fetch(
    `${apiPrefix}/gis/admin_name_tree?product_type=ACTIVITY`
  );

  if (response.status === 200) {
    return response.json();
  } else {
    return { ok: false };
  }
}